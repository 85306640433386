<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="snackbar" color="#ff6200" right :timeout="3000">
      <v-layout wrap>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="snackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pa-0 pa-sm-4>
      <v-flex xs12>
        <v-layout wrap justify-start py-2>
          <v-flex xs12 align-self-center>
            <span
              class="title1"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '25px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '25px'
                    : '30px',
              }"
              >Houseboat reservations</span
            >
          </v-flex>
        </v-layout>
        <v-card color="transparent" elevation="0">
          <v-tabs
            v-model="myTab"
            background-color="transparent"
            color="#ff6200"
            left
          >
            <v-tab
              :style="
                myTab == 0
                  ? 'background-color:#ff6200'
                  : 'background-color:white'
              "
            >
              <span
                :style="myTab == 0 ? 'color:white' : 'color:black'"
                style="
                  font-family: LexendFont;
                  font-weight: 500;
                  font-size: 14px;
                "
                >Current</span
              ></v-tab
            >
            <v-tab
              :style="
                myTab == 1
                  ? 'background-color:#ff6200'
                  : 'background-color:white'
              "
            >
              <span
                :style="myTab == 1 ? 'color:white' : 'color:black'"
                style="
                  font-family: LexendFont;
                  font-weight: 500;
                  font-size: 14px;
                "
                >cancelled</span
              ></v-tab
            >
            <v-tab-item>
              <v-layout wrap justify-end v-if="list">
                <v-flex
                  xs12
                  sm4
                  md3
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pt-2
                  pb-2
                >
                  <v-text-field
                    v-model="keyword1"
                    dense
                    placeholder="Search"
                    class="rounded-0"
                    outlined
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md2
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pt-2
                  pb-2
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="fromDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate"
                        outlined
                        placeholder="From Date"
                        readonly
                        dense
                        hide-details
                        clearable
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate"
                      no-title
                      scrollable
                      @change="$refs.menu.save(fromDate)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md2
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pt-2
                  pb-2
                >
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="toDate"
                    transition="scale-transition"
                    offset-y
                    left
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate"
                        readonly
                        outlined
                        placeholder="To date"
                        dense
                        hide-details
                        clearable
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate"
                      no-title
                      scrollable
                      @change="$refs.menu1.save(toDate)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center v-if="list">
                <v-flex xs12 v-if="list.length > 0">
                  <v-card elevation="0">
                    <v-layout
                      wrap
                      justify-center
                      class="hidden-sm-and-down"
                      pb-5
                    >
                      <v-flex xs12 px-4 v-for="(item, i) in list" :key="i" pt-1>
                        <v-card
                          elevation="0"
                          color="#EDEDED"
                          style="border-radius: 12px"
                        >
                          <v-layout wrap justify-center>
                            <v-flex xs3>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-img
                                    style="border-radius: 12px 0px 0px 12px"
                                    :src="
                                      mediaUURL + item.houseBoatId.coverImage
                                    "
                                    :height="
                                      $vuetify.breakpoint.name == 'xs'
                                        ? '180px'
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? '180px'
                                        : $vuetify.breakpoint.name == 'md'
                                        ? '200px'
                                        : $vuetify.breakpoint.name == 'lg'
                                        ? '180px'
                                        : '180px'
                                    "
                                    width="250px"
                                    ><template v-slot:placeholder>
                                      <ImageLoader /> </template
                                  ></v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs9 pl-6>
                              <v-layout wrap justify-start fill-height>
                                <v-flex xs4 text-left align-self-center>
                                  <v-layout wrap fill-height>
                                    <v-flex xs12 align-self-start>
                                      <span
                                        style="
                                          font-weight: 500;
                                          font-size: 20px;
                                          font-weight: bold;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        {{ item.houseBoatId.houseBoatName }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Category :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.houseBoatId.category }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Reservation # :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.reservationNo }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-in :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkInDate) }}
                                        </span>
                                        <!-- <span style="color: #f17343" v-if="item.selectedDate">
                                          {{ formatDate(item.selectedDate[0]) }}
                                          </span>
                                          <span style="color: #f17343" v-if="item.selectedDate.length>1">
                                          - {{ formatDate(item.selectedDate[item.selectedDate.length-1]) }}
                                        </span> -->
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-out :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkOutDate) }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <!-- <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Location :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.houseBoatId.location"
                                        >
                                          {{ item.houseBoatId.location.name }}
                                        </span>
                                      </span>
                                    </v-flex> -->
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs5
                                  text-left
                                  align-self-end
                                  pb-0
                                  pb-md-3
                                  pl-2
                                >
                                  <v-layout wrap fill-height>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Trip type :&nbsp;
                                        <span>
                                          {{ item.tripType }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      pt-2
                                      v-if="item"
                                      align-self-start
                                    >
                                      <span
                                        style="
                                          font-weight: 400;
                                          font-size: 14px;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        Booked for : &nbsp;
                                      </span>
                                      <span
                                        v-if="item.guestName"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        {{ item.guestName }}</span
                                      >
                                      <span
                                        v-if="item.guestPhoneNumber"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        ,{{ item.guestPhoneNumber }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Advance :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.advancePayment"
                                        >
                                          ₹ {{ item.advancePayment }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      text-left
                                      pt-2
                                      align-self-end
                                      v-if="item.balancepaymentStatus"
                                    >
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Balance :&nbsp;

                                        <span
                                          style="color: red"
                                          v-if="
                                            item.balancepaymentStatus ==
                                              'Pending' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Not Paid
                                        </span>
                                        <span
                                          style="color: green"
                                          v-else-if="
                                            item.balancepaymentStatus ==
                                              'Success' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Paid
                                        </span>
                                        <span style="color: green" v-else>
                                          ₹ {{ item.balancePayAmount }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Balance payed by :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.balancePayedBy"
                                        >
                                          {{ item.balancePayedBy }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Start/Drop Location :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="
                                            item.houseBoatId.startingLocation
                                          "
                                        >
                                          {{
                                            item.houseBoatId.startingLocation
                                              .name
                                          }}
                                        </span>
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex xs3 align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex
                                      xs12
                                      text-center
                                      pl-3
                                      pr-1
                                      pb-2
                                      v-if="item.totalPayableAmount"
                                    >
                                      <span
                                        style="
                                          font-family: LexendFont;
                                          color: black;
                                          font-size: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        ₹ {{ item.totalPayableAmount }}
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      sm7
                                      text-center
                                      pr-0
                                      pr-md-2
                                      pb-2
                                    >
                                      <v-btn
                                        @click="
                                          $router.push(
                                            '/houseboatReservationEdit?id=' +
                                              item._id +
                                              '&&boatid=' +
                                              item.houseBoatId._id
                                          )
                                        "
                                        small
                                        dark
                                        block
                                        color="#F17343"
                                      >
                                        <span
                                          style="
                                            font-family: LexendFont;
                                            color: white;
                                            font-size: 14px;
                                            font-weight: 300;
                                            text-transform: none;
                                          "
                                          ><v-icon
                                            small
                                            color="white"
                                            class="pb-1"
                                            >mdi-pencil</v-icon
                                          >
                                          Edit
                                        </span>
                                      </v-btn>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      sm7
                                      text-center
                                      pr-0
                                      pr-md-2
                                      pb-2
                                    >
                                      <v-btn
                                        @click="
                                          (cancelDialog = true),
                                            (deleteId = item._id)
                                        "
                                        small
                                        dark
                                        block
                                        color="#F17343"
                                      >
                                        <span
                                          style="
                                            font-family: LexendFont;
                                            color: white;
                                            font-size: 14px;
                                            font-weight: 300;
                                            text-transform: none;
                                          "
                                          ><v-icon
                                            small
                                            color="white"
                                            class="pb-1"
                                            >mdi-cancel</v-icon
                                          >
                                          Cancel
                                        </span>
                                      </v-btn>
                                    </v-flex>
                                    <!-- <v-flex xs12 text-center pl-3 pr-1>
                                                                <span
                                                                    style="font-family: LexendFont; color: black; font-size: 10px; font-weight: 600">
                                                                    ₹ {{ item.bookingTotal }}
                                                                </span>
                                                            </v-flex> -->
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <v-layout wrap justify-center class="hidden-md-and-up" pb-6>
                      <v-flex xs11 v-for="(item, i) in list" :key="i" pt-1>
                        <v-card
                          elevation="0"
                          color="#EDEDED"
                          style="border-radius: 12px"
                        >
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-img
                                    style="border-radius: 12px 12px 0px 0px"
                                    :src="
                                      mediaUURL + item.houseBoatId.coverImage
                                    "
                                    height="150px"
                                    width="100%"
                                    ><template v-slot:placeholder>
                                      <ImageLoader /> </template
                                  ></v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12>
                              <v-layout wrap justify-center fill-height>
                                <v-flex xs12 text-left align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex xs11 pt-2>
                                      <span
                                        style="
                                          font-weight: 500;
                                          font-size: 20px;
                                          font-weight: bold;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        {{ item.houseBoatId.houseBoatName }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Category :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.houseBoatId.category }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-2>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Reservation # :
                                      </span>
                                      <span
                                        style="color: #f17343; font-size: 15px"
                                      >
                                        &nbsp; {{ item.reservationNo }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-in :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkInDate) }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-out :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkOutDate) }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Trip type :&nbsp;
                                        <span>
                                          {{ item.tripType }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <!-- <v-flex xs11 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                        v-if="item.houseBoatId.location"
                                      >
                                        Location :&nbsp;
                                        <span>
                                          {{ item.houseBoatId.location.name }}
                                        </span>
                                      </span>
                                    </v-flex> -->
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs11
                                  pt-0
                                  pt-md-3
                                  text-left
                                  align-self-center
                                >
                                  <v-layout wrap justify-center>
                                    <v-flex xs12 text-left v-if="item">
                                      <span
                                        style="
                                          font-weight: 400;
                                          font-size: 14px;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        Booked for : &nbsp;
                                      </span>
                                      <span
                                        v-if="item.guestName"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        {{ item.guestName }}</span
                                      >
                                      <span
                                        v-if="item.guestPhoneNumber"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        ,{{ item.guestPhoneNumber }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.advancePayment"
                                      >
                                        Advance :&nbsp;
                                        <span style="color: #f17343">
                                          ₹ {{ item.advancePayment }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <v-flex xs12 text-left pt-1 pt-md-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                        v-if="item.balancepaymentStatus"
                                      >
                                        Balance :&nbsp;

                                        <span
                                          style="color: red"
                                          v-if="
                                            item.balancepaymentStatus ==
                                              'Pending' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Not Paid
                                        </span>
                                        <span
                                          style="color: green"
                                          v-else-if="
                                            item.balancepaymentStatus ==
                                              'Success' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Paid
                                        </span>
                                        <span style="color: green" v-else>
                                          ₹ {{ item.balancePayAmount }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.balancePayedBy"
                                      >
                                        Balance payed by :&nbsp;
                                        <span style="color: #f17343">
                                          {{ item.balancePayedBy }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.houseBoatId.startingLocation"
                                      >
                                        Start/Drop Location :&nbsp;
                                        <span style="color: #f17343">
                                          {{
                                            item.houseBoatId.startingLocation
                                              .name
                                          }}
                                        </span>
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex pt-3 xs11 align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex xs12 text-center pb-2>
                                      <span
                                        v-if="item.totalPayableAmount"
                                        style="
                                          font-family: LexendFont;
                                          color: black;
                                          font-size: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        ₹ {{ item.totalPayableAmount }}
                                      </span>
                                    </v-flex>

                                    <v-flex pb-3 xs12 text-center>
                                      <v-btn
                                        @click="
                                          $router.push(
                                            '/houseboatReservationEdit?id=' +
                                              item._id +
                                              '&&boatid=' +
                                              item.houseBoatId._id
                                          )
                                        "
                                        small
                                        dark
                                        block
                                        color="#F17343"
                                      >
                                        <span
                                          style="
                                            font-family: LexendFont;
                                            color: white;
                                            font-size: 14px;
                                            font-weight: 400;
                                            text-transform: none;
                                          "
                                        >
                                          <v-icon
                                            small
                                            color="white"
                                            class="pb-1"
                                            >mdi-pencil</v-icon
                                          >
                                          Edit
                                        </span>
                                      </v-btn>
                                    </v-flex>
                                    <v-flex pb-3 xs12 text-center>
                                      <v-btn
                                        @click="
                                          (cancelDialog = true),
                                            (deleteId = item._id)
                                        "
                                        small
                                        dark
                                        block
                                        color="#F17343"
                                      >
                                        <span
                                          style="
                                            font-family: LexendFont;
                                            color: white;
                                            font-size: 14px;
                                            font-weight: 400;
                                            text-transform: none;
                                          "
                                        >
                                          <v-icon
                                            small
                                            color="white"
                                            class="pb-1"
                                            >mdi-cancel</v-icon
                                          >
                                          Cancel
                                        </span>
                                      </v-btn>
                                    </v-flex>
                                    <!-- <v-flex xs12 text-center pl-3 pr-1>
                                                                <span
                                                                    style="font-family: LexendFont; color: black; font-size: 10px; font-weight: 600">
                                                                    ₹ {{ item.bookingTotal }}
                                                                </span>
                                                            </v-flex> -->
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 pa-4 v-else>
                  <span
                    style="
                      font-weight: 400;
                      font-size: 20px;
                      font-family: LexendRegular;
                      text-align: center;
                    "
                  >
                    No reservations found
                  </span>
                </v-flex>
              </v-layout>

              <v-layout v-if="list.length > 0" wrap justify-center pb-3>
                <v-flex xs11>
                  <v-layout wrap>
                    <v-flex xs12 id="specific-section">
                      <v-pagination
                        small
                        color="#ff6200"
                        v-model="page"
                        :length="Pagelength"
                        :total-visible="7"
                      >
                      </v-pagination>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-dialog width="400px" v-model="cancelDialog">
                <v-card width="400px" class="pa-2">
                  <v-layout wrap justify-center>
                    <v-flex xs11 text-center
                      ><span style="color: #002635" class="dialogHead"
                        >Cancel</span
                      ></v-flex
                    >
                    <v-flex xs1 text-right>
                      <v-icon @click="cancelDialog = false" color="#002635"
                        >mdi-close-box</v-icon
                      ></v-flex
                    >
                    <v-flex xs8 text-center py-4>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                  <v-card-text class="px-4 pb-0 dialogText text-center"
                    >Are you sure you want to cancel this reservation?
                  </v-card-text>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#002635"
                      class="dialogText"
                      dark
                      @click="cancelReservation()"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-tab-item>
            <v-tab-item>
              <v-layout wrap justify-end v-if="list2">
                <v-flex
                  xs12
                  sm4
                  pt-2
                  md3
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pb-2
                >
                  <v-text-field
                    v-model="keyword2"
                    dense
                    placeholder="Search"
                    class="rounded-0"
                    outlined
                    hide-details
                    clearable
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  pt-2
                  sm4
                  md2
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pb-2
                >
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="fromDate2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fromDate2"
                        outlined
                        readonly
                        hide-details
                        dense
                        clearable
                        placeholder="From Date"
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fromDate2"
                      no-title
                      scrollable
                      @change="$refs.menu2.save(fromDate2)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  sm4
                  md2
                  text-right
                  pr-2
                  pl-2
                  pl-md-0
                  pr-md-4
                  pt-2
                  pb-2
                >
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="toDate2"
                    transition="scale-transition"
                    offset-y
                    left
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="toDate2"
                        readonly
                        outlined
                        dense
                        placeholder="To Date"
                        hide-details
                        clearable
                        class="rounded-0"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="toDate2"
                      no-title
                      scrollable
                      @change="$refs.menu3.save(toDate2)"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-center v-if="list2">
                <v-flex xs12 v-if="list2.length > 0">
                  <v-card elevation="0">
                    <v-layout
                      wrap
                      justify-center
                      class="hidden-sm-and-down"
                      pb-5
                    >
                      <v-flex
                        xs12
                        px-4
                        v-for="(item, i) in list2"
                        :key="i"
                        pt-1
                      >
                        <v-card
                          elevation="0"
                          color="#EDEDED"
                          style="border-radius: 12px"
                        >
                          <v-layout wrap justify-center>
                            <v-flex xs3>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-img
                                    style="border-radius: 12px 0px 0px 12px"
                                    :src="
                                      mediaUURL + item.houseBoatId.coverImage
                                    "
                                    :height="
                                      $vuetify.breakpoint.name == 'xs'
                                        ? '180px'
                                        : $vuetify.breakpoint.name == 'sm'
                                        ? '180px'
                                        : $vuetify.breakpoint.name == 'md'
                                        ? '200px'
                                        : $vuetify.breakpoint.name == 'lg'
                                        ? '180px'
                                        : '180px'
                                    "
                                    width="250px"
                                    ><template v-slot:placeholder>
                                      <ImageLoader /> </template
                                  ></v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs9 pl-6>
                              <v-layout wrap justify-start fill-height>
                                <v-flex xs4 text-left align-self-center>
                                  <v-layout wrap fill-height>
                                    <v-flex xs12 align-self-start>
                                      <span
                                        style="
                                          font-weight: 500;
                                          font-size: 20px;
                                          font-weight: bold;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        {{ item.houseBoatId.houseBoatName }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Category :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.houseBoatId.category }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Reservation # :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.reservationNo }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-in :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkInDate) }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-out :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkOutDate) }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <!-- <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Location :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.houseBoatId.location"
                                        >
                                          {{ item.houseBoatId.location.name }}
                                        </span>
                                      </span>
                                    </v-flex> -->
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs5
                                  text-left
                                  align-self-end
                                  pb-0
                                  pb-md-3
                                  pl-2
                                >
                                  <v-layout wrap fill-height>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Trip type :&nbsp;
                                        <span>
                                          {{ item.tripType }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex
                                      xs12
                                      pt-2
                                      v-if="item"
                                      align-self-start
                                    >
                                      <span
                                        style="
                                          font-weight: 400;
                                          font-size: 14px;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        Booked for : &nbsp;
                                      </span>
                                      <span
                                        v-if="item.guestName"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        {{ item.guestName }}</span
                                      >
                                      <span
                                        v-if="item.guestPhoneNumber"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        ,{{ item.guestPhoneNumber }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Advance :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.advancePayment"
                                        >
                                          ₹ {{ item.advancePayment }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <v-flex
                                      xs12
                                      text-left
                                      pt-2
                                      align-self-end
                                      v-if="item.balancepaymentStatus"
                                    >
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Balance :&nbsp;

                                        <span
                                          style="color: red"
                                          v-if="
                                            item.balancepaymentStatus ==
                                              'Pending' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Not Paid
                                        </span>
                                        <span
                                          style="color: green"
                                          v-else-if="
                                            item.balancepaymentStatus ==
                                              'Success' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Paid
                                        </span>
                                        <span style="color: green" v-else>
                                          ₹ {{ item.balancePayAmount }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Balance payed by :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="item.balancePayedBy"
                                        >
                                          {{ item.balancePayedBy }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                      >
                                        Start/Drop Location :&nbsp;
                                        <span
                                          style="color: #f17343"
                                          v-if="
                                            item.houseBoatId.startingLocation
                                          "
                                        >
                                          {{
                                            item.houseBoatId.startingLocation
                                              .name
                                          }}
                                        </span>
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex xs3 align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex
                                      xs12
                                      text-center
                                      pl-3
                                      pr-1
                                      pb-2
                                      v-if="item.totalPayableAmount"
                                    >
                                      <span
                                        style="
                                          font-family: LexendFont;
                                          color: black;
                                          font-size: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        ₹ {{ item.totalPayableAmount }}
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>

                    <v-layout wrap justify-center class="hidden-md-and-up" pb-6>
                      <v-flex xs11 v-for="(item, i) in list2" :key="i" pt-1>
                        <v-card
                          elevation="0"
                          color="#EDEDED"
                          style="border-radius: 12px"
                        >
                          <v-layout wrap justify-center>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-img
                                    style="border-radius: 12px 12px 0px 0px"
                                    :src="
                                      mediaUURL + item.houseBoatId.coverImage
                                    "
                                    height="150px"
                                    width="100%"
                                    ><template v-slot:placeholder>
                                      <ImageLoader /> </template
                                  ></v-img>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex xs12>
                              <v-layout wrap justify-center fill-height>
                                <v-flex xs12 text-left align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex xs11 pt-2>
                                      <span
                                        style="
                                          font-weight: 500;
                                          font-size: 20px;
                                          font-weight: bold;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        {{ item.houseBoatId.houseBoatName }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Category :
                                      </span>
                                      <span
                                        style="
                                          color: #f17343;
                                          font-size: 15px;
                                          font-family: LexendFont;
                                        "
                                      >
                                        &nbsp; {{ item.houseBoatId.category }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-2>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Reservation # :
                                      </span>
                                      <span
                                        style="color: #f17343; font-size: 15px"
                                      >
                                        &nbsp; {{ item.reservationNo }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-in :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkInDate) }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Check-out :&nbsp;
                                        <span style="color: #f17343">
                                          {{ formatDate(item.checkOutDate) }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs11 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                      >
                                        Trip type :&nbsp;
                                        <span>
                                          {{ item.tripType }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <!-- <v-flex xs11 pt-2 align-self-end>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                        v-if="item.houseBoatId.location"
                                      >
                                        Location :&nbsp;
                                        <span>
                                          {{ item.houseBoatId.location.name }}
                                        </span>
                                      </span>
                                    </v-flex> -->
                                  </v-layout>
                                </v-flex>

                                <v-flex
                                  xs11
                                  pt-0
                                  pt-md-3
                                  text-left
                                  align-self-center
                                >
                                  <v-layout wrap justify-center>
                                    <v-flex xs12 text-left v-if="item">
                                      <span
                                        style="
                                          font-weight: 400;
                                          font-size: 14px;
                                          font-family: LexendRegular;
                                        "
                                      >
                                        Booked for : &nbsp;
                                      </span>
                                      <span
                                        v-if="item.guestName"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        {{ item.guestName }}</span
                                      >
                                      <span
                                        v-if="item.guestPhoneNumber"
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 400;
                                        "
                                      >
                                        ,{{ item.guestPhoneNumber }}
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.advancePayment"
                                      >
                                        Advance :&nbsp;
                                        <span style="color: #f17343">
                                          ₹ {{ item.advancePayment }}
                                        </span>
                                      </span>
                                    </v-flex>

                                    <v-flex xs12 text-left pt-1 pt-md-3>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 15px;
                                          font-weight: 500;
                                        "
                                        v-if="item.balancePayAmount"
                                      >
                                        Balance :&nbsp;

                                        <span
                                          style="color: red"
                                          v-if="
                                            item.balancepaymentStatus ==
                                              'Pending' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Not Paid
                                        </span>
                                        <span
                                          style="color: green"
                                          v-else-if="
                                            item.balancepaymentStatus ==
                                              'Success' &&
                                            item.advancepaymentMode == 'Agent'
                                          "
                                        >
                                          ₹ {{ item.balancePayAmount }} Paid
                                        </span>
                                        <span style="color: green" v-else>
                                          ₹ {{ item.balancePayAmount }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.balancePayedBy"
                                      >
                                        Balance payed by :&nbsp;
                                        <span style="color: #f17343">
                                          {{ item.balancePayedBy }}
                                        </span>
                                      </span>
                                    </v-flex>
                                    <v-flex xs12 text-left pt-1 pt-md-4>
                                      <span
                                        style="
                                          font-family: LexendRegular;
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        v-if="item.houseBoatId.startingLocation"
                                      >
                                        Start/Drop Location :&nbsp;
                                        <span style="color: #f17343">
                                          {{
                                            item.houseBoatId.startingLocation
                                              .name
                                          }}
                                        </span>
                                      </span>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>

                                <v-flex pt-3 xs11 align-self-center>
                                  <v-layout wrap justify-center>
                                    <v-flex xs12 text-center pb-2>
                                      <span
                                        v-if="item.totalPayableAmount"
                                        style="
                                          font-family: LexendFont;
                                          color: black;
                                          font-size: 20px;
                                          font-weight: 600;
                                        "
                                      >
                                        ₹ {{ item.totalPayableAmount }}
                                      </span>
                                    </v-flex>

                                    <!-- <v-flex pb-3 xs12 text-center>
                                <v-btn
                                  @click="gotoView(item.houseboatBookId)"
                                  block
                                  color="#F17343"
                                >
                                  <span
                                    style="
                                      font-family: LexendFont;
                                      color: white;
                                      font-size: 14px;
                                      font-weight: 400;
                                      text-transform: none;
                                    "
                                  >
                                   Edit
                                  </span>
                                </v-btn>
                              </v-flex> -->
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs12 pa-4 v-else>
                  <span
                    style="
                      font-weight: 400;
                      font-size: 20px;
                      font-family: LexendRegular;
                      text-align: center;
                    "
                  >
                    No reservations found
                  </span>
                </v-flex>
              </v-layout>

              <v-layout v-if="list2.length > 0" wrap justify-center pb-3>
                <v-flex xs11>
                  <v-layout wrap>
                    <v-flex xs12 id="specific-section">
                      <v-pagination
                        small
                        color="#002635"
                        v-model="page2"
                        :length="Pagelength2"
                        :total-visible="7"
                      >
                      </v-pagination>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      snackbar: false,
      list: [],
      list2: [],
      timeout: 5000,
      msg: null,
      page: 1,
      limit: 20,
      page2: 1,
      limit2: 20,
      deleteId: "",
      carouselSpeed: 1000,
      cancelDialog: false,
      value: "",
      phone: "",
      currentPage: 1,
      myTab: "",
      keyword1: "",
      keyword2: "",
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      toDate: "",
      toDate2: "",
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      fromDate2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  watch: {
    page() {
      this.getList();
    },
    page2() {
      this.getcancelledList();
    },
    keyword1() {
      this.getList();
    },
    keyword2() {
      this.getcancelledList();
    },
    toDate() {
      this.getList();
    },
    toDate2() {
      this.getcancelledList();
    },
    fromDate() {
      this.getList();
    },
    fromDate2() {
      this.getcancelledList();
    },
  },
  mounted() {
    this.getList();
    this.getcancelledList();
  },

  methods: {
    formattedDate(date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Date(date).toLocaleDateString("en-GB", options);
    },
    formatTime(time) {
      if (!time) return "";
      const formattedTime = new Date(time).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      return formattedTime;
    },
    getList() {
      this.appLoading = true;

      axios({
        method: "get",
        url: "/reservation/all/houseboat",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: this.limit,
          keyword: this.keyword1,
          toDate: this.toDate,
          fromDate: this.fromDate,
        },
      }).then((response) => {
        if (response.data.status == true) {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.Pagelength = Math.ceil(response.data.totalLength / this.limit);
          this.appLoading = false;
        } else {
          this.appLoading = false;

          this.msg = response.data.msg;
          this.snackbar = true;
        }
      });
      // }
    },
    getcancelledList() {
      this.appLoading = true;

      axios({
        method: "get",
        url: "/reservation/houseboat/cancelled/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page2,
          limit: this.limit2,
          keyword: this.keyword2,
          toDate: this.toDate2,
          fromDate: this.fromDate2,
        },
      }).then((response) => {
        if (response.data.status == true) {
          this.list2 = response.data.data;
          this.Pagelength2 = Math.ceil(response.data.totalLength / this.limit2);
          this.appLoading = false;
        } else {
          this.appLoading = false;

          this.msg = response.data.msg;
          this.snackbar = true;
        }
      });
      // }
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    formatTimeNew(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      // var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        // day + " " + dt.slice(4, 7) + " " + year;
        // +
        // " , " +
        hours + ":" + minutes + " " + ampm;

      return strTime;
    },
    cancelReservation() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/reservation/houseboat/cancel",
        params: {
          id: this.deleteId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showsnackbar = true;
          this.cancelDialog = false;
          this.getList();
          this.getcancelledList();
          this.myTab = 1;
        } else {
          this.msg = response.data.msg;
          this.showsnackbar = true;
        }
      });
      // }
    },
  },
};
</script>